import React from "react";

export const onRenderBody = ({ setHtmlAttributes, setPostBodyComponents, setHeadComponents  }) => {
    setHtmlAttributes({ lang: 'en-GB' });

    setHeadComponents([
        <script
            key="gatsby-plugin-google-gtag"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-HKBZ7G8ZML&dbg=4955`}
        />,
        <script
            async
            key="gatsby-plugin-google-gtag-config"
            dangerouslySetInnerHTML={{
                __html: `
           window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-HKBZ7G8ZML');
        `,
            }}
        />,
    ]);

    setPostBodyComponents([
        <noscript
            key="gatsby-plugin-tag-manager"
            dangerouslySetInnerHTML={{
                __html: `
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NWR6BKK"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `,
            }}
        />,
    ]);
};